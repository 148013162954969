import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-564fe835"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "datagrid-container" }
const _hoisted_2 = {
  key: 1,
  class: "page-top"
}
const _hoisted_3 = {
  ref: "sectionRef",
  class: "row-container"
}
const _hoisted_4 = {
  key: 0,
  class: "mask"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_MajorSearch = _resolveComponent("MajorSearch")!
  const _component_Actions = _resolveComponent("Actions")!
  const _component_LoadingDataFailure = _resolveComponent("LoadingDataFailure")!
  const _component_RaTable = _resolveComponent("RaTable")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isInitialize || _ctx.isUpdate)
      ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.showTopBar)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          (_ctx.state.querying)
            ? (_openBlock(), _createBlock(_component_MajorSearch, {
                key: 0,
                aid: "major-search",
                state: _ctx.state.querying
              }, null, 8, ["state"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "aftersearch", {}, undefined, true),
          (_ctx.state.actions)
            ? (_openBlock(), _createBlock(_component_Actions, {
                key: 1,
                class: "action-buttons",
                state: _ctx.state.actions,
                selection: _ctx.state.selection,
                aid: _ctx.state.actions.global[0] ? _ctx.state.actions.global[0].label : null
              }, null, 8, ["state", "selection", "aid"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showDataFailure)
      ? (_openBlock(), _createBlock(_component_LoadingDataFailure, {
          key: 2,
          onRefresh: _ctx.refresh,
          float: ""
        }, null, 8, ["onRefresh"]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_3, [
      (_ctx.mover.state.moving)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4))
        : _createCommentVNode("", true),
      _createVNode(_component_RaTable, {
        class: "ra-table",
        style: _normalizeStyle({ flex: _ctx.tableFlex }),
        "row-id": _ctx.model.itemId,
        items: _ctx.state.display.items,
        "row-actions": _ctx.onItmeActions,
        "rows-selection": _ctx.state.selection,
        "dynamic-icon-class": _ctx.itemIconClass,
        pagination: _ctx.state.pagination,
        columns: _ctx.tableColumns,
        "row-props": _ctx.tableRowProps,
        sorting: _ctx.state.sorting,
        "sync-url": _ctx.state.syncUrl,
        "data-key": _ctx.state.dataKey,
        onAction: _ctx.onAction
      }, null, 8, ["style", "row-id", "items", "row-actions", "rows-selection", "dynamic-icon-class", "pagination", "columns", "row-props", "sorting", "sync-url", "data-key", "onAction"]),
      _createElementVNode("section", {
        class: _normalizeClass([{ animatable: !_ctx.mover.state.moving }, "detailes"]),
        style: _normalizeStyle({ flex: _ctx.detailesFlex })
      }, [
        (_ctx.showDetailsPanel)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "separator",
              onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mover.handleStart && _ctx.mover.handleStart(...args)))
            }, null, 32))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "item", {
          item: _ctx.singleSelectedItem,
          clearSelection: _ctx.clearSelection
        }, undefined, true)
      ], 6)
    ], 512),
    _createVNode(_component_Pagination, {
      state: _ctx.state.pagination
    }, null, 8, ["state"])
  ]))
}