import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77135371"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return (typeof _ctx.icon == 'string')
    ? (_openBlock(), _createElementBlock("i", {
        key: 0,
        class: _normalizeClass(_ctx.icon)
      }, null, 2))
    : (_ctx.icon.type == 'img')
      ? _withDirectives((_openBlock(), _createElementBlock("img", {
          key: 1,
          class: "icon-image",
          src: _ctx.icon.src
        }, null, 8, _hoisted_1)), [
          [_directive_tippy, { content: _ctx.icon.tooltip }]
        ])
      : _createCommentVNode("", true)
}