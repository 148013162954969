
import DeleteModal from './DeleteModal.vue';
import { DeleteModalAction as DeleteModalActionLogic } from '../../compositions/DeleteModalAction';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    components: { DeleteModal },
    props: {
        state: Object as PropType<DeleteModalActionLogic>,
    },
    setup(props) {
        return {};
    },
});
