
import Action from "./Action.vue";
import { Actions as ActionLogic, ItemsSelection } from "../compositions";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
    name: "Actions",
    components: { Action },
    props: {
        state: Object as PropType<ActionLogic>,
        selection: Object as PropType<ItemsSelection>,
    },
    setup: (props, cts) => {
        const self = {
            get showGlobalActions() {
                return !!self.global.length;
            },

            get showActionsOnItem() {
                if (!props.selection) return false;
                return props.selection.mode == "single";
            },

            onItem: computed(() => {
                if (!self.showActionsOnItem) return undefined;
                const item = props.selection!.firstItem;
                const actions = props
                    .state!.onItem.filter((a: any) => !a.filter || a.filter(item))
                    .map((a: any) => ({
                        ...a,
                        disabled: a.disabled ? a.disabled(item) : false,
                    }));
                return actions.length ? actions : undefined;
            }),

            get global() {
                return props
                    .state!.global.filter((a: any) => !a.filter || a.filter())
                    .map((a: any) => ({
                        ...a,
                        disabled: a.disabled ? a.disabled() : false,
                    }));
            },

            emitOnItem(key: string) {
                props.state!.emit(key, props.selection!.firstItem);
            },

            emitGlobal(key: string) {
                props.state!.emit(key, undefined);
            },
        };
        return self;
    },
});
