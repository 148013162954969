import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from './images/search-icon.svg'


const _hoisted_1 = ["onClick", "onMousemove"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuArrow = _resolveComponent("MenuArrow")!
  const _directive_on_clickaway = _resolveDirective("on-clickaway")!

  return (_ctx.opened)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass({
            [_ctx.$style.container]: true,
            [_ctx.$style.containerPositionBottom]: _ctx.position === 'bottom',
            [_ctx.$style.containerPositionLeft]: _ctx.position === 'left',
        }),
        ref: "containerRef"
      }, [
        _createVNode(_component_MenuArrow, { position: _ctx.position }, null, 8, ["position"]),
        (_ctx.searchable)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass([_ctx.$style.dropDownItem, _ctx.$style.searchBox]),
              onKeypress: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.emitClick(_ctx.options[_ctx.hoveredIndex])), ["enter"]))
            }, [
              _createElementVNode("img", {
                src: _imports_0,
                class: _normalizeClass(_ctx.$style.searchIcon)
              }, null, 2),
              _createElementVNode("input", {
                ref: "searchBoxRef",
                type: "search",
                placeholder: "Search",
                class: _normalizeClass(_ctx.$style.searchInput),
                onKeydown: [
                  _cache[0] || (_cache[0] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.pressedDown && _ctx.pressedDown(...args)), ["prevent"]), ["down"])),
                  _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.pressedUp && _ctx.pressedUp(...args)), ["prevent"]), ["up"]))
                ],
                onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.searchInputChanged && _ctx.searchInputChanged(...args)))
              }, null, 34)
            ], 34))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.optionsCollection),
          onMouseleave: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hoveredIndex = null))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              ref_for: true,
              ref: 
                    (el) => {
                        _ctx.optionsRef[index] = el;
                    }
                ,
              key: option.key || option.title,
              class: _normalizeClass({
                    [_ctx.$style.selectOption]: true,
                    [_ctx.$style.selectOptionHovered]: _ctx.hoveredIndex === index,
                    [_ctx.$style.dropDownItem]: true,
                    [_ctx.$style.disabled]: option.disabled,
                }),
              onClick: ($event: any) => (!option.disabled && _ctx.emitClick(option)),
              onMousemove: ($event: any) => (_ctx.hoveredIndex = index)
            }, [
              _renderSlot(_ctx.$slots, option.key, {}, () => [
                _createElementVNode("div", {
                  class: _normalizeClass(_ctx.$style.optionText)
                }, _toDisplayString(option.title), 3)
              ])
            ], 42, _hoisted_1))
          }), 128))
        ], 34)
      ], 2)), [
        [_directive_on_clickaway, _ctx.emitClose]
      ])
    : _createCommentVNode("", true)
}