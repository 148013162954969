import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteModal = _resolveComponent("DeleteModal")!

  return (_ctx.state.isOpening)
    ? (_openBlock(), _createBlock(_component_DeleteModal, {
        key: 0,
        multi: _ctx.state.isMulti,
        "entity-type": _ctx.state.entityType,
        name: _ctx.state.displayName,
        note: _ctx.state.note,
        onDelete: _ctx.state.delete,
        onCancel: _ctx.state.cancel
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["multi", "entity-type", "name", "note", "onDelete", "onCancel"]))
    : _createCommentVNode("", true)
}