import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "delete-modal-body" }
const _hoisted_2 = { class: "delete-modal-msg" }
const _hoisted_3 = { class: "delete-modal-info" }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, "Permanently delete " + _toDisplayString(_ctx.entityType) + " " + _toDisplayString(_ctx.name) + "?", 1),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(" Once a " + _toDisplayString(_ctx.entityType) + " is deleted, it cannot be restored. ", 1),
      (_ctx.note)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.note), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}