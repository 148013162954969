import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Action = _resolveComponent("Action")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.actionsContainer)
  }, [
    (_ctx.onItem)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.onItem, (action) => {
            return (_openBlock(), _createBlock(_component_Action, {
              key: action.key,
              class: _normalizeClass(_ctx.$style.actionButton),
              action: action,
              onEmit: _ctx.emitOnItem,
              aid: action.aid
            }, null, 8, ["class", "action", "onEmit", "aid"]))
          }), 128)),
          (_ctx.showGlobalActions)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.seperator)
              }, "|", 2))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.showGlobalActions)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.global, (action) => {
          return (_openBlock(), _createBlock(_component_Action, {
            key: action.key,
            class: _normalizeClass(_ctx.$style.actionButton),
            action: action,
            onEmit: _ctx.emitGlobal,
            aid: action.aid
          }, null, 8, ["class", "action", "onEmit", "aid"]))
        }), 128))
      : _createCommentVNode("", true)
  ], 2))
}