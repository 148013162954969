
import { Column } from "@/core-ui/types/column";
import { DropdownMenuOption } from "@/core-ui/types/dropdown-menu-option";
import DynamicCheckbox from "@/core-ui/forms/components/DynamicCheckbox.vue";
import RaMenu from "@/core-ui/RaMenu.vue";
import { ShownColumns } from "../compositions";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    components: {
        RaMenu,
    },
    props: {
        columns: Array as PropType<Array<Column>>,
        state: Object as PropType<ShownColumns>,
    },
    setup: (props, ctx) => {
        const self = {
            mapDropdownOptions(columns: Array<Column>): Array<DropdownMenuOption> {
                return columns.map(({ key, label }) => ({ key, title: label } as DropdownMenuOption));
            },

            get options(): Array<DropdownMenuOption> {
                return self.mapDropdownOptions(props.columns!);
            },
        };
        return self;
    },
});
