
import { DisplayDensityOptions } from "@/core-ui/data-grid/types";

export default {
    props: {
        headerStyle: Object,
        flex: Boolean,
        density: { default: DisplayDensityOptions.Compact },
    },
};
