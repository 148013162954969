
import { defineComponent, PropType } from "vue";
import { Pagination as PaginationLogic, usePagination } from "../compositions";
// future: use items per page isiste pagination
// import ItemsPerPage from "./ItemsPerPage.vue"

export default defineComponent({
    props: {
        state: { type: Object as PropType<PaginationLogic>, required: true },
    },
});
