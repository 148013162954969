
import { Column } from "@/core-ui/types/column";
import { Querying } from "@/core-ui/forms/compositions";
import { defineComponent, PropType, ref } from "vue";

// future: pass the logic from here to the useQuering
export default defineComponent({
    props: {
        state: Object as PropType<Querying>,
    },
    setup(props, ctx) {
        const dirty = ref(false);
        const hideSuggestions = ref(false);
        const hoveredSuggestionIndex = ref(-1);
        const qRef = ref();
        //trigger initial search query filter
        props.state?.emitSearch(props.state?.query);
        const self = {
            qRef,
            dirty,
            hideSuggestions,
            hoveredSuggestionIndex,
            get suggestions(): Array<Column> {
                let lastText = props.state!.query.split(" ").pop() as string;
                if (lastText) {
                    lastText = lastText.toLowerCase();
                    return props.state!.searchableColumns.filter((c) => {
                        return c.key.toLowerCase().includes(lastText) || c.label.toLowerCase().includes(lastText);
                    });
                } else {
                    return [];
                }
            },

            onPress(event: KeyboardEvent) {
                hideSuggestions.value = false;
                if (!dirty.value) {
                    dirty.value = true;
                }
                if (event.keyCode === 27) {
                    //ESC
                    self.onEsc();
                } else if (event.keyCode === 40 || event.keyCode === 38) {
                    event.preventDefault();
                    self.onArrows(event.keyCode);
                }
            },

            onEsc() {
                hoveredSuggestionIndex.value = -1;
                hideSuggestions.value = true;
            },

            onArrows(keyCode: number) {
                if (keyCode === 40) {
                    // DOWN
                    hoveredSuggestionIndex.value++;
                } else if (keyCode === 38) {
                    // UP
                    hoveredSuggestionIndex.value--;
                }

                if (hoveredSuggestionIndex.value >= self.suggestions.length) {
                    hoveredSuggestionIndex.value = 0;
                } else if (hoveredSuggestionIndex.value < 0) {
                    hoveredSuggestionIndex.value = self.suggestions.length - 1;
                }
            },

            selectSuggestion(suggestion: Column) {
                const lastQuery = props.state!.query.split(" ");
                lastQuery.pop();
                props.state!.emitSearch(lastQuery.concat(suggestion.key + ":").join(" "));
                hoveredSuggestionIndex.value = -1;
                (qRef.value as HTMLInputElement).focus();
            },

            clear() {
                props.state!.emitSearch("");
                dirty.value = true;
            },

            submit() {
                if (hoveredSuggestionIndex.value > -1) {
                    self.selectSuggestion(self.suggestions[hoveredSuggestionIndex.value]);
                }
            },
        };
        return self;
    },
});
