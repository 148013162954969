import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-inline-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MDBBtn = _resolveComponent("MDBBtn")!
  const _directive_tippy = _resolveDirective("tippy")!

  return (_ctx.tooltip)
    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MDBBtn, {
          color: "link",
          disabled: !!_ctx.action.disabled,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.action.disabled && _ctx.$emit('emit', _ctx.action.key)))
        }, {
          default: _withCtx(() => [
            _createElementVNode("i", {
              class: _normalizeClass([_ctx.$style.actionIcon, _ctx.action.icon])
            }, null, 2),
            _createTextVNode(" " + _toDisplayString(_ctx.action.label), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ])), [
        [_directive_tippy, { content: _ctx.tooltip }]
      ])
    : (_openBlock(), _createBlock(_component_MDBBtn, {
        key: 1,
        color: "link",
        disabled: !!_ctx.action.disabled,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.action.disabled && _ctx.$emit('emit', _ctx.action.key)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("i", {
            class: _normalizeClass([_ctx.$style.actionIcon, _ctx.action.icon])
          }, null, 2),
          _createTextVNode(" " + _toDisplayString(_ctx.action.label), 1)
        ]),
        _: 1
      }, 8, ["disabled"]))
}