
import { useDataGrid, DataGridAction, DataGridModel, toTableColumns } from "../compositions";
import RaTable from "./RaTable.vue";
import Pagination from "./Pagination.vue";
import MajorSearch from "@/core-ui/forms/components/MajorSearch.vue";
import Actions from "./Actions.vue";
import Loading from "@/core-ui/Loading.vue";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import LoadingDataFailure from "./LoadingDataFailure.vue";
import { useMover } from "../compositions/Mover";
import { storageService } from "../services/storage-service";
import { debounce } from "lodash";
import { JOBS_TABLE_FILTER } from "@/cluster-ui/helpers/const";

export default defineComponent({
    components: {
        RaTable,
        MajorSearch,
        Pagination,
        Actions,
        Loading,
        LoadingDataFailure,
    },
    props: {
        model: { type: Object as PropType<DataGridModel>, required: true },
        actions: { type: Array as PropType<DataGridAction[]>, default: () => [] },
        syncUrl: Boolean,
        hiddenKeys: { type: Array as PropType<string[]>, default: [""] },
        initialSearchQuery: { type: String as PropType<string>, default: undefined },
    },
    setup(props, ctx) {
        const sectionRef = ref<HTMLElement>(null as any);
        const resizeKey = computed(() => props.model.dataKey + "/resize-detailes-last-state");

        const lastState = storageService.getNum(resizeKey.value, 1);
        // model.dataKey
        const tableFlex = ref(lastState);
        const detailesFlex = ref(2 - lastState);

        const tmpDetailesFlex = ref(null as any);
        const tmpTableFlex = ref(null as any);
        const maxFlex = 1.98;
        const minFlex = 0.05;
        // use debounce to avoid too many resize event
        const emitResize = debounce(() => {
            window.dispatchEvent(new Event("resize"));
        }, 25);

        const mover = useMover({
            onStart: () => {
                tmpDetailesFlex.value = detailesFlex.value;
                tmpTableFlex.value = tableFlex.value;
            },
            onMove: (s: any) => {
                const delta = s.start[0] - s.current[0];
                const size = sectionRef.value.clientWidth;
                const zz = delta / (size / 2);

                tmpDetailesFlex.value = detailesFlex.value + zz;
                tmpTableFlex.value = tableFlex.value - zz;

                if (tmpDetailesFlex.value < minFlex) {
                    tmpDetailesFlex.value = minFlex;
                    tmpTableFlex.value = maxFlex;
                }

                if (tmpDetailesFlex.value > maxFlex) {
                    tmpDetailesFlex.value = maxFlex;
                    tmpTableFlex.value = minFlex;
                }
                emitResize();
            },
            onEnd: () => {
                detailesFlex.value = tmpDetailesFlex.value;
                tableFlex.value = tmpTableFlex.value;
                tmpDetailesFlex.value = null;
                tmpTableFlex.value = null;
                if (tableFlex.value == maxFlex) {
                    self.clearSelection();
                }
                storageService.setNum(resizeKey.value, tableFlex.value);
            },
        });

        const state = useDataGrid({
            get model() {
                return props.model;
            },
            get actions() {
                return props.actions;
            },
            get syncUrl() {
                return props.syncUrl;
            },
            get initialSearchQuery() {
                return props.initialSearchQuery;
            },
        });
        watch(
            () => state.querying?.query,
            (newValue) => {
                if (props.model.tableName === "job") {
                    localStorage.setItem(JOBS_TABLE_FILTER, newValue || "");
                }
            },
        );
        const self = {
            mover,
            state,
            sectionRef,
            get showDetailsPanel() {
                return ctx.slots.item && !!self.singleSelectedItem;
            },
            get tableFlex() {
                return tmpTableFlex.value !== null ? tmpTableFlex.value : self.showDetailsPanel ? tableFlex.value : 1;
            },
            get tableRowProps() {
                return props.model.entryProps?.tableRow;
            },
            get detailesFlex() {
                return tmpDetailesFlex.value !== null
                    ? tmpDetailesFlex.value
                    : self.showDetailsPanel
                    ? detailesFlex.value
                    : 0;
            },
            get singleSelectedItem() {
                if (!self.state.selection) return null;
                const { mode, firstItem } = self.state.selection;
                return mode == "single" ? firstItem : null;
            },

            get onItmeActions() {
                return self.state.actions ? self.state.actions.onItem : [];
            },

            get itemDetailsIsOpen() {
                return true;
            },

            get itemIconClass() {
                return props.model.meta.icon;
            },

            get isInitialize() {
                return props.model.state == "initialize";
            },

            get isUpdate() {
                return props.model.state == "update";
            },

            get tableColumns() {
                return toTableColumns(self.state.columns, props.hiddenKeys);
            },

            get showTopBar() {
                return !!self.state.querying || !!self.state.actions;
            },

            onAction({ action, item }: any) {
                (self as any).state.actions.emit(action, item);
            },

            clearSelection() {
                self.state.selection?.clear();
            },

            refresh() {
                props.model.refresh();
            },

            get showDataFailure() {
                return props.model.state == "failure";
            },
        };
        return self;
    },
});
