
import { Column } from "@/core-ui/types/column";
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        column: Object as PropType<Column>,
        value: {},
        row: Object,
    },

    setup: (props) => ({
        get className(){
            if(props.column!.key === 'jobUrl'){
                return 'break-spaces-class'
            }
            return ''
        },
        get display() {
            return props.column!.dataTransform ? props.column!.dataTransform(props.value, props.row) : props.value;
        },
    }),
});
