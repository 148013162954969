
import { useDisplayDensity, DisplayDensity } from "../compositions/DisplayDensity";
import { defineComponent, watch } from "vue";
import { DisplayDensityOptions } from "../types";

export default defineComponent({
    props: {
        density: Object,
    },
    setup(props) {
        const defDensity = useDisplayDensity();
        let state = defDensity;
        watch(
            () => props.density,
            () => {
                state = (props.density as DisplayDensity) ?? defDensity;
            },
            { immediate: true },
        );
        return {
            get state() {
                return state;
            },
            get options() {
                return Object.entries(DisplayDensityOptions);
            },
        };
    },
});
