
import { DropdownMenuOption } from "@/core-ui/types/dropdown-menu-option";
import { mixin as clickaway } from "@/core-ui/directives/clickaway";
import MenuArrow from "@/core-ui/MenuArrow.vue";
import { defineComponent, onBeforeUpdate, PropType, ref, watch } from "vue";

type Offset = {
    top?: number;
    bottom?: number;
    width?: number;
    height?: number;
};

export default defineComponent({
    components: { MenuArrow },
    mixins: [clickaway],
    emits: ["optionClick", "close", "searchInput"],
    props: {
        opened: Boolean,
        options: Array as PropType<DropdownMenuOption[]>,
        offset: Object as PropType<Offset>,
        searchable: { type: Boolean, default: false },
        stackOnTop: { type: Boolean, default: false },
        position: {
            default: "bottom",
            type: String as PropType<"bottom" | "left">,
        },
    },

    created() {
        this.theComponnet = this as any;
    },

    setup(props, ctx) {
        const hoveredIndex = ref<number | null>(null);
        const searchBoxRef = ref();
        const containerRef = ref();
        const optionsRef = ref([]);

        let component: any = null;

        onBeforeUpdate(() => {
            optionsRef.value = [];
        });

        watch(
            () => props.opened,
            async function () {
                hoveredIndex.value = null;
                await component.$nextTick();
                if (props.opened) {
                    if (props.searchable) {
                        const searchBox = searchBoxRef.value as HTMLElement;
                        searchBox.focus();
                        ctx.emit("searchInput", "");
                    }

                    if (props.stackOnTop) {
                        self.makeContianerOnTop();
                    }
                }
            },
        );
        watch(
            () => props.offset,
            () => {
                if (!(props.opened && props.offset && containerRef.value)) {
                    return;
                }
                self.makeContianerOnTop();
            },
        );

        var self = {
            hoveredIndex,
            searchBoxRef,
            containerRef,
            optionsRef,
            set theComponnet(v: { $style: any }) {
                component = v;
            },
            pressedDown() {
                if (hoveredIndex.value == undefined) {
                    hoveredIndex.value = 0;
                } else {
                    hoveredIndex.value = Math.min(hoveredIndex.value + 1, (props.options as any).length - 1);
                }

                this.scrollIntoHoveredIndex();
            },

            pressedUp() {
                if (hoveredIndex.value == undefined) {
                    hoveredIndex.value = 0;
                } else {
                    hoveredIndex.value = Math.max(hoveredIndex.value - 1, 0);
                }

                this.scrollIntoHoveredIndex();
            },

            scrollIntoHoveredIndex() {
                const optionsRefs = optionsRef.value as HTMLElement[];
                const element = optionsRefs[hoveredIndex.value as number] as HTMLElement;

                element.scrollIntoView({
                    block: "nearest",
                });
            },

            searchInputChanged(event: any) {
                hoveredIndex.value = null;
                ctx.emit("searchInput", event.target.value);
            },
            makeContianerOnTop() {
                const currentElement = containerRef.value as HTMLElement;
                if (!currentElement) {
                    return;
                }
                const { left, top, width } = currentElement.getBoundingClientRect();
                //const height = 500
                const offset: any = props.offset || {};

                currentElement.style.position = "fixed";
                currentElement.style.left = (offset.left || left) + "px";
                currentElement.style.height = offset.height ? offset.height + "px" : (undefined as any);
                currentElement.style.top = (offset.top || top) + "px";
                currentElement.style.width = (offset.width || width) + "px";

                // Remove the original position class
                if (props.position === "bottom") {
                    currentElement.classList.remove(component.$el.style.containerPositionBottomPosition);
                } else {
                    currentElement.classList.remove(component.style.containerPositionLeft);
                }
            },

            emitClose() {
                ctx.emit("close");
            },

            emitClick(option: DropdownMenuOption) {
                if (option.onClick) {
                    option.onClick();
                }
                ctx.emit("optionClick", option);
            },
        };
        return self;
    },
});
