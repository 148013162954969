import { reactive } from "vue";

type MoverState = {
    current: [number, number];
    start: [number, number];
    moving: boolean;
};
type MoverCallbackFunc = (state: MoverState, e: MouseEvent) => void;
type MoverProps = {
    onStart?: MoverCallbackFunc;
    onMove?: MoverCallbackFunc;
    onEnd?: MoverCallbackFunc;
};

export function useMover({ onStart, onMove, onEnd }: MoverProps) {
    const state = reactive<MoverState>({
        start: [0, 0],
        current: [0, 0],
        moving: false,
    });

    function onResizeStart(e: MouseEvent) {
        const p: [number, number] = [e.clientX, e.clientY];
        state.start = p;
        state.current = p;
        state.moving = true;
        document.addEventListener("mousemove", onResizeMove);
        document.addEventListener("mouseup", onResizeEnd);
        if (onStart) onStart(state, e);
    }

    function onResizeMove(e: MouseEvent) {
        state.current = [e.clientX, e.clientY];
        if (onMove) onMove(state, e);
    }

    function onResizeEnd(e: MouseEvent) {
        // remove listeners from the events listener
        document.removeEventListener("mousemove", onResizeMove);
        document.removeEventListener("mouseup", onResizeEnd);
        state.moving = false;
        if (onEnd) onEnd(state, e);
    }

    return {
        state,
        handleStart: onResizeStart,
    };
}
