
import { defineComponent, PropType } from "vue";
import { Action } from "../compositions";
export default defineComponent({
    name: "Action",
    props: { action: Object as PropType<Action> },
    setup(props) {
        return {
            get tooltip() {
                const disabled = props.action?.disabled;
                return disabled && typeof disabled == "string" ? disabled : "";
            },
        };
    },
});
