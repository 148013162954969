
import { defineComponent } from "vue";
import TextButton from "@/core-ui/TextButton.vue";

export default defineComponent({
    components: { TextButton },
    props: {
        float: Boolean,
        msg: { type: String, default: "Failed to load data" },
        actionLabel: { type: String, default: "Try Again" },
    },
});
