
import Modal from '@/core-ui/Modal.vue';
import Button from '@/core-ui/Button.vue';
import { Component, defineComponent, ref } from 'vue';
import DeleteModalBody from './DeleteModalBody.vue';

export default defineComponent({
    components: { Modal, Button, DeleteModalBody },
    emits: ['cancel', 'delete'],
    props: {
        multi: Boolean,
        name: String,
        entityType: String,
        note: String,
    },
    setup(props, ctx) {
        const clicked = ref(false);
        const deleteItButtonRef = ref<Component | null>(null);
        const self = {
            clicked,
            deleteItButtonRef,
            get title(): string {
                return `Delete`;
            },

            cancel() {
                ctx.emit('cancel');
            },

            emitDelete() {
                if (clicked.value) return;
                clicked.value = true;
                ctx.emit('delete');
            },
        };
        return self;
    },
});
