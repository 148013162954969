import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    class: _normalizeClass(_ctx.$style.majorSearch),
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.inputContainer)
    }, [
      (!_ctx.state.query)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass(["raicon-search", _ctx.$style.searchIcon])
          }, null, 2))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        placeholder: "       Search...",
        class: _normalizeClass(_ctx.$style.searchInput),
        name: "q",
        autocomplete: "off",
        ref: "qRef",
        onKeydown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPress && _ctx.onPress(...args))),
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.state.emitSearch($event.target.value))),
        value: _ctx.state.query
      }, null, 42, _hoisted_1),
      (_ctx.state.query)
        ? (_openBlock(), _createElementBlock("i", {
            key: 1,
            class: _normalizeClass(["raicon-close", _ctx.$style.inputIcon]),
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)))
          }, null, 2))
        : _createCommentVNode("", true),
      (!_ctx.hideSuggestions && _ctx.suggestions.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.$style.suggestions)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.suggestions, (s, i) => {
              return (_openBlock(), _createElementBlock("div", {
                onClick: ($event: any) => (_ctx.selectSuggestion(s)),
                key: s.key,
                class: _normalizeClass({
                        [_ctx.$style.suggestionRow]: true,
                        [_ctx.$style.hoveredRow]: _ctx.hoveredSuggestionIndex === i,
                    })
              }, [
                _createTextVNode(_toDisplayString(s.label) + " ", 1),
                _createElementVNode("small", null, "(" + _toDisplayString(s.key) + ")", 1)
              ], 10, _hoisted_2))
            }), 128))
          ], 2))
        : _createCommentVNode("", true)
    ], 2)
  ], 34))
}