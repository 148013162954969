import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteModalBody = _resolveComponent("DeleteModalBody")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    title: _ctx.title,
    type: "error",
    onCancel: _ctx.cancel
  }, {
    body: _withCtx(() => [
      _createVNode(_component_DeleteModalBody, {
        note: _ctx.note,
        "entity-type": _ctx.entityType,
        name: _ctx.name
      }, null, 8, ["note", "entity-type", "name"])
    ]),
    buttons: _withCtx(() => [
      _createVNode(_component_Button, {
        autofocus: "",
        class: _normalizeClass(_ctx.$style.deleteButton),
        "button-type": "main",
        ref: "deleteItButtonRef",
        text: "Delete",
        onClick: _ctx.emitDelete,
        clicked: _ctx.clicked,
        aid: "delete-modal-delete-btn"
      }, null, 8, ["class", "onClick", "clicked"]),
      _createVNode(_component_Button, {
        text: "Cancel",
        onClick: _ctx.cancel,
        aid: "delete-modal-cancel-btn"
      }, null, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["title", "onCancel"]))
}