
import { defineComponent } from 'vue';

export default defineComponent({
    emits: ['cancel', 'delete'],
    props: {
        name: String,
        entityType: String,
        note: String,
    },
});
