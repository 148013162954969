
import Table from "./Table2.vue";
import Row from "./Row.vue";
import Icon from "./Icon.vue";
import DropDownMenu from "@/core-ui/DropDownMenu.vue";
import ColumnsSelector from "./ColumnsSelector.vue";
import RaTableCell from "./RaTableCell.vue";
import ItemsPerPage from "./ItemsPerPage.vue";
import DisplayDensity from "./DisplayDensity.vue";

import { TableColumn } from "@/core-ui/types/column";
import { OrderDirection } from "@/core-ui/types/order";
import { DropdownMenuOption } from "@/core-ui/types/dropdown-menu-option";

import { Sorting, ItemsSelection, Pagination, ItemAction, useTableColumns } from "@/core-ui/data-grid/compositions";
import { defineComponent, PropType, ref, computed } from "vue";
import { getCellProps } from "../utils";
// inited by setup function
// columning: TableColumns;
export default defineComponent({
    components: {
        ItemsPerPage,
        RaTableCell,
        ColumnsSelector,
        DropDownMenu,
        Table,
        Icon,
        Row,
        DisplayDensity,
    },
    emits: ["action"],
    props: {
        rowId: { type: String },
        rowActions: Array as PropType<Array<ItemAction>>,
        items: Array,
        dynamicIconClass: [Function, String],
        columns: { type: Array as PropType<TableColumn[]>, default: () => [] },
        rowProps: Function,
        syncUrl: { type: Boolean, default: () => false },
        dataKey: String,
        sorting: { type: Object as PropType<Sorting>, default: () => ({}) },
        rowsSelection: Object as PropType<ItemsSelection>,
        pagination: Object as PropType<Pagination>,
    },
    created() {
        this.thisComponent = this;
    },
    setup(props, ctx) {
        // saved the item that the action menu open on it
        const selectedItem = ref<any>(null);
        const dropdownOffset = ref<any>(null);
        const dropdownPosition = ref<"left" | "bottom">("left");
        const orderTypes = OrderDirection;
        const thisComponent = ref<any>();
        // const leftCell: string;
        // const rightCell: Array<string>;

        const columning = useTableColumns({
            get syncUrl() {
                return props.syncUrl;
            },
            get sorting() {
                return props.sorting;
            },
            get columns() {
                return props.columns;
            },
            get key(): string {
                return props.dataKey!;
            },
        });

        const cellCtx = computed(() => ({
            selector: props.rowsSelection,
        }));

        const self = {
            cellCtx,
            orderTypes,
            selectedItem,
            dropdownOffset,
            dropdownPosition,
            columning,
            thisComponent,
            get shownColumns() {
                return self.columning.shown;
            },

            get columnsOrder() {
                return self.columning.orderer;
            },

            get columnsSize() {
                return self.columning.sizer;
            },
            get multipleLine() {
                return self.columning.multipleLine;
            },

            // return all meta data of the column to clean up the view
            get headerColumnsData() {
                const orderer = self.columnsOrder;
                const cz = self.columnsSize;
                return self.relevantColumns.map((column) => {
                    // curentlly every columns are sortable
                    const isSortable = !!props.sorting?.isSortable(column.dataKey);
                    const sortStatus = isSortable ? props.sorting.columnStatus(column.dataKey) : null;

                    return {
                        column,
                        isSortable,
                        sortStatus,
                        get isDroppable() {
                            return orderer.dragged && orderer.dragged !== column.key;
                        },
                        get isDragged() {
                            return column.key == orderer.dragged;
                        },
                        get isDraggedOver() {
                            return column.key == orderer.draggedOver;
                        },
                        get isResizing() {
                            return column.key == cz.activeKey;
                        },
                    };
                });
            },

            get relevantColumns() {
                let columns = self.columning.flatColumns;
                if (self.shownColumns) {
                    columns = columns.filter(({ key }) => self.shownColumns.show(key));
                }
                return columns;
            },

            get orderedItems() {
                return props.items || null;
            },

            dropdownOptions: computed<Array<DropdownMenuOption> | null>(() => {
                const isThereActions = !!props.rowActions?.length;
                if (!isThereActions) return null;

                const options = props
                    .rowActions!.filter((a: any) => !a.filter || (selectedItem.value && a.filter(selectedItem.value)))
                    .map(({ key, label, disabled }) => {
                        return {
                            key,
                            title: label,
                            disabled: disabled && selectedItem.value ? disabled(selectedItem.value) : false,
                        };
                    });
                return options;
            }),

            getCellProps,

            async onAction(action: DropdownMenuOption) {
                const actionData = {
                    action: action.key,
                    item: selectedItem.value,
                };
                self.toggleDropdownMenu(null);
                await thisComponent.value.$nextTick(); // todo
                ctx.emit("action", actionData);
            },

            sortColumn(key: string) {
                props.sorting.toggle(key);
            },

            columnStyle(key: string) {
                const width = this.columnsSize.get(key);
                const multipleLine = this.multipleLine[key]
                if (width && multipleLine) {
                    return {
                        minWidth: "200px",
                        width: "100%",
                        mWidth: width + "px",
                        whiteSpace: "break-spaces",
                        wordBreak: "break-word",
                    }
                } else
                if (width) {
                    return {
                        minWidth: width + "px",
                        width: width + "px",
                        mWidth: width + "px"
                    }
                } else {
                    return null
                }
            },

            toggleDropdownMenu(item: any) {
                if (!item || selectedItem.value === item) {
                    selectedItem.value = null;
                } else {
                    dropdownOffset.value = null;
                    dropdownPosition.value = "left";
                    selectedItem.value = item;
                }
            },

            rowClicked(item: any) {
                props.rowsSelection?.toggle(item[props.rowId!]);
            },

            rightClick(event: MouseEvent, item: unknown) {
                dropdownOffset.value = {
                    top: event.clientY + 10,
                    left: event.clientX - 50,
                    width: 130,
                };
                dropdownPosition.value = "bottom";
                selectedItem.value = item;
            },
        };
        return self;
    },
});
