import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "table2-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          class: "header",
          style: _normalizeStyle($props.headerStyle),
          ref: "header"
        }, [
          _renderSlot(_ctx.$slots, "header")
        ], 4))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass(["body", $props.density])
    }, [
      _renderSlot(_ctx.$slots, "body")
    ], 2)
  ]))
}